import React from 'react';
import Header from '../components/header';
import Foundation from './foundation';

export default function Layout({ children }) {
	return (
		<Foundation>
			<Header />
			{children}
		</Foundation>
	)
}

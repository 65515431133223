import React from 'react';
import Layout from './layout';
import styled from 'styled-components';
import { format } from 'date-fns';
import SEO from '../components/seo';
import { TiMail } from 'react-icons/ti';
import Image from '../components/image';
import { Time } from '../components/styles';

const PostDetail = styled.main`
	margin: 0 auto;
	padding: 64px 20px;
	max-width: 1100px;

	h1 {
		margin: 0;
		margin-bottom: 2rem;
		font-family: 'Crimson Pro', serif;
		font-size: 2.4rem;
		line-height: 1.2;

		span {
			color: deeppink;
		}
	}

	.primary-image {
		margin-bottom: 32px;

		@media (min-width: 64em) {
			float: right;
			margin-left: 48px;
			width: 100%;
			max-width: 600px;
		}
	}

	p {
		max-width: 72ch;
		font-size: 1.2rem;
		line-height: 1.8;
	}

	a {
		font-style: italic;
		font-weight: 700;
		color: inherit;
		text-decoration: none;
		text-shadow:
			2px 2px 0 rgba(0, 213, 255, 0.5),
			-2px -2px 0 rgba(255, 105, 180, 0.5);
		background-image: linear-gradient(to bottom, #212121 0%, #212121 100%);
		background-repeat: no-repeat;
		background-size: 100% 1px;
		background-position: left bottom;
		transition: text-shadow 200ms cubic-bezier(0,0,0.3,1);

		&:hover {
			text-shadow:
				4px 4px 0 rgba(0, 213, 255, 0.5),
				-4px -4px 0 rgba(255, 105, 180, 0.5);
		}
	}

	.signature {
		display: flex;
		padding-top: 64px;

		span {
			margin-right: 4px;
			color: deeppink;
		}
	}
`

export default function Post(props) {
	const { children, pageContext: { frontmatter } } = props;
	const { title, date, ...image } = frontmatter;

	return (
		<Layout>
			<SEO title={title} />
			<PostDetail>
				<Time dateTime={date}>
					<TiMail className="icon" />
					{`${format(new Date(date), 'MMMM dd, yyyy')}`}
				</Time>
				<h1>{title} <span aria-hidden="true">&mdash;</span></h1>
				{image && (
					<Image
						className="primary-image"
						filename={`${image.image_filename}.${image.image_ext}`}
						alt={image.image_alt}
					/>
				)}
				{children}
			</PostDetail>
		</Layout>
	)
}

import styled from "styled-components";

export const Time = styled.time`
	display: flex;
	align-items: center;
	font-size: 0.8rem;
	line-height: 1.1;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: deeppink;

	.icon {
		margin-right: 6px;
		width: 16px;
		height: 16px;
	}
`

export const Figure = styled.figure`
	position: relative;
	margin: 0;

	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}

	&::before {
		content: "";
		position: absolute;
		top: -40px;
		left: -80px;
		width: 160px;
		height: 160px;
		border-radius: 50%;
		background-color: rgba(0, 213, 255, 0.5);
		mix-blend-mode: multiply;
	}

	&::after {
		content: "";
		position: absolute;
		z-index: 1;
		right: -20px;
		bottom: 48px;
		width: 100%;
		height: 128px;
		background-color: #e41a65;
		mix-blend-mode: multiply;
	}

	@media (min-width: 40em) {
		grid-column: 3 / -1;
		grid-row: 1 / -1;
	}
`;

import React from 'react';
import styled from 'styled-components';
import { TiHeartFullOutline } from "react-icons/ti";
import { Link } from 'gatsby';

const TopBar = styled.header`
	.inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 auto;
		padding: 0 20px;
		max-width: 1100px;
		height: 50px;
	}

	.logo {
		width: 24px;
		height: 24px;
		color: hotpink;
	}

	.classic {
		font-family: 'Courier New', Courier, monospace;
		font-weight: 600;
		text-decoration: none;
		color: #212121;
	}
`;

export default function Header() {
	return (
		<TopBar>
			<div className="inner">
				<Link to="/">
					<TiHeartFullOutline className="logo" />
				</Link>

				<a className="nav-link classic" href="https://arkmuntasser.github.io/to-becks/" rel="noopener">Classic Mode</a>
			</div>
		</TopBar>
	)
}

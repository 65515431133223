import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import Helmet from 'react-helmet';

const GlobalStyle = createGlobalStyle`
	${normalize}

	body {
		margin: 0;
		padding: 0;
		font-family: 'Lato', sans-serif;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.6;
		background-color: #fff;
		background-image:
			url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23e8e8e8' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E"),
			linear-gradient(66deg, rgba(0,0,0,0) 60%, hsl(330, 100%, 95%) 60%);
		background-attachment: fixed;
	}

	p {
		margin: 0;
		margin-bottom: 1rem;
	}
`;

export default function Foundation({ children }) {
	return (
		<>
			<Helmet>
				<link
					href="https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@1,900&family=Lato:ital,wght@0,400;1,700&display=swap"
					rel="stylesheet"
				/>
			</Helmet>
			<GlobalStyle />
			{children}
		</>
	)
}
